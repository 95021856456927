<template>
<div class="transcribe-status">

    <div v-if="canTranscribe">
        <div class="transcribe-quota">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">check</i>
          <span>{{$t('transcript.statusCount1')}}</span>
          <span class="bold">{{leftoverQuota}}</span>
          <span>{{$t('transcript.statusCount2')}}</span>

        </div>
    </div>

    <div v-if="!canTranscribe">
        <div class="transcribe-quota">
          <i aria-hidden="true" class="v-icon material-icons theme--light green-text">error_outline</i>
          <span>{{$t('transcript.statusExceed')}}</span>
          <span class="bold">{{-leftoverQuota}} {{$t('transcript.minutes')}}</span>
          <!-- <span>{{"(Your balance: " + remainingQuota + " minutes)"}}</span> -->
        </div>

    <div v-if="false">
        <v-btn small dark color="#0BAAFF" class ="trans-cta">{{$t('transcript.statusBuyMinutes')}}</v-btn>
        <v-btn small dark color="#0BAAFF" class ="trans-cta">{{$t('transcript.statusSignUp')}}</v-btn>
    </div>

        <p class="error-text">{{$t('transcript.statusError')}}</p>

    </div>

</div>


</template>

<script>
export default {
    props: ["leftoverQuota", "remainingQuota"],
    computed: {
        canTranscribe() {
            return this.leftoverQuota >= 0
        },
        // pendingQuota () {
        //     return this.userDetails.PendingTranscribeQuota
        // },
        // totalQuota () {
        //     return this.userDetails.TranscribeQuota
        // },
        // remainingQuota () {
        // return this.totalQuota - (this.pendingQuota + this.currentTranscribeQuota)
        // },
    }
}
</script>

<style scoped>

.transcribe-status {
    width: 100%;
    margin: 0.5em auto 1.5em auto;
}

.transcribe-quota {
    text-align: left;
    display: flex;
    justify-items: flex-start;
    /* border: 1px solid red; */
}

.transcribe-quota span {
    margin-right: 4px;
}

.trans-cta {
    border-radius: 4px;
}

p {
    text-align: left;
}

</style>
